import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { ICustomer } from '@/shared/model/customer.model';
import LazyAuthImage from '@/shared/components/lazy-auth-image/lazy-auth-image.vue';
import { Authority } from '@/shared/security/authority';
import CustomerService from '@/customer/customer.service';
import { PromptDialogContent } from '@/shared/model/prompt-dialog-content.model';
import { Alert, IAlert } from '@/shared/model/alert.model';
import { AlertType } from '@/shared/model/enumerations/alert-type.model';
import { IStatus } from '@/shared/model/status.model';
import StatusService from '@/entities/status/status.service';

@Component({
  components: { LazyAuthImage },
})
export default class AppNavbar extends Vue {
  @Inject('customerService') private customerService: () => CustomerService;
  @Inject('statusService') private statusService: () => StatusService;

  private changing = false;
  private statuses: IStatus[] = [];

  @Watch('showCustomerId')
  async handleShowCustomerId() {
    if (this.showCustomerId) {
      await this.getStatuses();
    }
  }

  private async getStatuses() {
    this.statusService()
      .retrieve()
      .then(res => {
        this.statuses = res;
      })
      .catch(err => {
        console.error(err);
      });
  }

  changeTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  private async disableIsDirectCall() {
    try {
      this.$store.commit(
        'appStore/setPromptDialogContent',
        new PromptDialogContent(
          'Bestätigung erforderlich',
          'sind Sie sicher, dass Sie die Funktion Direct Call deaktivieren möchten?',
          'Ja',
          'Nein'
        )
      );
      await this.$store.dispatch('appStore/promptDialog');
      this.changing = true;
      this.customerService()
        .disableDirectCall(this.customer.id)
        .then(res => {
          this.customer.directCall = null;
        })
        .catch(err => console.log(err))
        .finally(() => (this.changing = false));
    } catch (error) {
      if (error) {
        this.snackbarContent.push(new Alert(AlertType.ERROR, 'Es ist ein Fehler passiert'));
      }
    }
  }

  private get snackbarContent(): IAlert[] {
    return this.$store.getters['appStore/getSnackbarContent'];
  }

  private set snackbarContent(newSnackbarContent: IAlert[]) {
    this.$store.dispatch('appStore/setSnackbarContent', newSnackbarContent).then(null);
  }

  private toggleDrawer() {
    this.$emit('toggleDrawer');
  }

  private closeDrawer() {
    this.$emit('closeDrawer');
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  private get isNotCustomerRoute() {
    return this.$route.fullPath != '/customer';
  }

  private get isCalculatorRoute() {
    return this.$route.fullPath === '/calculator';
  }

  private get customer(): ICustomer {
    return this.$store.getters['appStore/getCustomer'];
  }

  private set customer(newCustomer: ICustomer) {
    this.$store.dispatch('appStore/setCustomer', newCustomer).then(null);
  }

  private get showCustomerId() {
    return this.customer != null && this.customer.customerId != null && this.$route.path == '/calculator';
  }

  private generateRandomColor(index: number) {
    const luxuryColors = [
      '#D4AF37',
      '#C0C0C0',
      '#8C7853',
      '#800020',
      '#0047AB',
      '#50C878',
      '#E0115F',
      '#FFD700',
      '#FF4500',
      '#A020F0',
      '#8B4513',
      '#1C1C1C',
      '#4B0082',
      '#2C3E50',
      '#228B22',
      '#DAA520',
      '#FF69B4',
      '#4682B4',
      '#B22222',
      '#E5C100',
    ];

    return luxuryColors[index];
  }

  private get hasAddress() {
    return (
      this.customer != null &&
      this.customer.propertyData != null &&
      this.customer.propertyData.objectAddress &&
      this.customer.propertyData.objectAddress.zipcode != null
    );
  }

  private get isAdmin() {
    return (
      this.$store.getters.account != null &&
      this.$store.getters.account.authorities != null &&
      this.$store.getters.account.authorities.includes(Authority.ADMIN)
    );
  }

  private get hasPartner() {
    return this.customer != null && this.customer.secondPerson != null && this.customer.secondPerson.firstname != null;
  }

  private async saveAndGoToCustomerList() {
    if (this.isCalculatorRoute) {
      this.savingCustomer = true;
    } else {
      await this.$router.push('/customer');
    }
  }

  private get savingCustomer(): boolean {
    return this.$store.getters['appStore/getSavingCustomer'];
  }

  private set savingCustomer(newSavingCustomer: boolean) {
    this.$store.dispatch('appStore/setSavingCustomer', newSavingCustomer).then(null);
  }
}
