var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-col",
    { staticClass: "pa-0" },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "removeScrollBar",
          class: _vm.mini ? "custom-active" : "",
          attrs: {
            "mini-variant": _vm.mini,
            permanent: "",
            "expand-on-hover": "",
            width: "270px",
          },
          on: {
            "update:miniVariant": function ($event) {
              _vm.mini = $event
            },
            "update:mini-variant": function ($event) {
              _vm.mini = $event
            },
          },
          scopedSlots: _vm._u(
            [
              _vm.authenticated
                ? {
                    key: "append",
                    fn: function () {
                      return [
                        _c(
                          "v-list-item",
                          { on: { click: _vm.logout } },
                          [
                            _c(
                              "v-list-item-icon",
                              [_c("v-icon", [_vm._v("mdi-logout")])],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-list-item-content", [
                              _c("span", [_vm._v("Ausloggen")]),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list-item",
            { staticStyle: { height: "80px" } },
            [
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "me-3 mt-5",
                      staticStyle: {
                        "border-radius": "8px",
                        border: "1px solid #f6f6f6",
                        background: "#fff",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.mini ? "mdi-chevron-right" : "mdi-chevron-left"
                        ) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-list-item-title", [
                _c("img", {
                  staticClass: "main-logo",
                  attrs: {
                    src: require("../../../content/images/logo2.png"),
                    alt: "logo",
                    width: "711",
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _vm.isAdmin
            ? _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-account-circle")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-content", [_vm._v(_vm._s(_vm.username))]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/system-analyse" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-chart-areaspline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("System analyse")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/estimate" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-information-outline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Estimating")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { attrs: { flat: "", color: _vm.dataGroupCardColor } },
                    [
                      _c(
                        "v-list-group",
                        {
                          attrs: { "prepend-icon": "mdi-tune" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c("v-list-item-title", [
                                      _vm._v("System Parameter"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1349740619
                          ),
                          model: {
                            value: _vm.systemParameterGroup,
                            callback: function ($$v) {
                              _vm.systemParameterGroup = $$v
                            },
                            expression: "systemParameterGroup",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list-item-group",
                            [
                              _c(
                                "v-list-item",
                                { attrs: { to: "/other-parameters" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-list-status")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Andere Parameter"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/status" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-list-status")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Status")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/digital-values" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-weight")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Digitalwert"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/age" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-tag-faces")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Altersgrenzen"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/life-expectancy" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-account-heart"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("stat. Sterbetabelle"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/guaranteed-values" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-home-thermometer"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Sofortrente Allianz"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/value-Declarer" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-cash-multiple"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("ImmoWert Quelle Kunde"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/province" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-map-marker-radius-outline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Grunderwerbssteuer"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/marital-status" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-account-heart"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Familienstand"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/document-type" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-file-document"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Unterlagentypen"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/customers-type" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-account-multiple"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Kundentypen"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/offer-type" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-circle")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v(" Angebotstypen"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/deviation" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-circle")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v(" Modell Abweichungen"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-group",
                        {
                          attrs: { "prepend-icon": "mdi-cog-outline" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c("v-list-item-title", [
                                      _vm._v(" Maklerbüro"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            427497112
                          ),
                          model: {
                            value: _vm.systemSetting,
                            callback: function ($$v) {
                              _vm.systemSetting = $$v
                            },
                            expression: "systemSetting",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list-item-group",
                            [
                              _c(
                                "v-list-item",
                                { attrs: { to: "/group-management" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-account-multiple"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Maklerbüros"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/system-emails" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-email-outline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("System Mail-Adressen"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/model-parameters" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-wrench")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Modelle Parameter"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/lead-sources" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-source-branch"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Quelle1")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/second-lead-sources" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-source-branch"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Quelle2")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/marketing-actions" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-arrow-expand-all"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Marketing Actions"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/sales-areas" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-salesforce")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Sales Areas"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/chance-weight" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-cash-100")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Estimating Levels"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/lead-generation-matrix" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-matrix")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Leadgenerator matrix"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/direct-call" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-account-voice"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Leadgenerator Direct Call"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/lead-parameters" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-web")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Leadgenerator Parameter"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/email-black-lists" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-email")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("VCO-Mailfilter"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/property-features" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-home-modern")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Immobilienmerkmale"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { attrs: { flat: "", color: _vm.dataGroupCardColor } },
                    [
                      _c(
                        "v-list-group",
                        {
                          attrs: { "prepend-icon": "mdi-account-tie-voice" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c("v-list-item-title", [
                                      _vm._v("Maklerbüroparameter"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3471107537
                          ),
                          model: {
                            value: _vm.marketerParameterGroup,
                            callback: function ($$v) {
                              _vm.marketerParameterGroup = $$v
                            },
                            expression: "marketerParameterGroup",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list-item-group",
                            [
                              _c(
                                "v-list-item",
                                { attrs: { to: "/default-display-value" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-monitor")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Wertanzeige"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/model-a-parameter" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-alpha-a-box")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Modell A")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/model-c-parameter" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-alpha-c-box")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Modell C")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/model-d-parameter" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-alpha-d-box")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Modell D")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/model-e-parameter" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-alpha-e-box")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Modell E")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/model-f-parameter" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-alpha-f-box")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Modell F")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAdmin
            ? _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: "/profile" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-account-circle")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-content", [_vm._v(_vm._s(_vm.username))]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/customer" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", [
                            _vm._v("mdi-account-multiple-outline"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Kunden")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isGroupAdmin
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/archived-customer" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-account-off")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Archivierte Kunden"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/calendar" } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-calendar-text")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Kalender")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isGroupAdmin
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/estimate" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-information-outline")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Estimating")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isGroupAdmin
                    ? _c(
                        "v-card",
                        { attrs: { flat: "", color: _vm.dataGroupCardColor } },
                        [
                          _c(
                            "v-list-group",
                            {
                              attrs: { "prepend-icon": "mdi-cog-outline" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function () {
                                      return [
                                        _c("v-list-item-title", [
                                          _vm._v(" Maklerbüro"),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                427497112
                              ),
                              model: {
                                value: _vm.systemSetting,
                                callback: function ($$v) {
                                  _vm.systemSetting = $$v
                                },
                                expression: "systemSetting",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list-item-group",
                                [
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/status" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-list-status"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("Status"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/system-emails" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-email-outline"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("System Mail-Adressen"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/model-parameters" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [_c("v-icon", [_vm._v("mdi-wrench")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("Modelle Parameter"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/notaries" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "mdi-book-open-page-variant"
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Notare"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/value-sources" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [_c("v-icon", [_vm._v("mdi-more")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Quelle ImmoWert"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/lead-sources" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-source-branch"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("Quelle1"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/second-lead-sources" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-source-branch"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("Quelle2"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/marketing-actions" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-arrow-expand-all"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("Marketing Quelle"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/sales-areas" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-salesforce"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("Sales Areas"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/user-management" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-account-multiple"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("Benutzerverwaltung"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/chance-weight" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-cash-100"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("Estimating Levels"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { to: "/lead-generation-matrix" },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [_c("v-icon", [_vm._v("mdi-matrix")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("Leadgenerator matrix"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/direct-call" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-account-voice"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v("Leadgenerator Direct Call"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/lead-parameters" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [_c("v-icon", [_vm._v("mdi-web")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Leadgenerator Parameter"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    { attrs: { to: "/property-features" } },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-home-modern"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Immobilienmerkmale"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { attrs: { flat: "", color: _vm.dataGroupCardColor } },
                    [
                      _c(
                        "v-list-group",
                        {
                          attrs: { "prepend-icon": "mdi-account-tie-voice" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c("v-list-item-title", [
                                      _vm._v("Berater parameter"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3381463033
                          ),
                          model: {
                            value: _vm.marketerParameterGroup,
                            callback: function ($$v) {
                              _vm.marketerParameterGroup = $$v
                            },
                            expression: "marketerParameterGroup",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list-item-group",
                            [
                              _c(
                                "v-list-item",
                                { attrs: { to: "/default-display-value" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-monitor")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [
                                    _vm._v("Wertanzeige"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/model-a-parameter" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-alpha-a-box")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Modell A")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/model-c-parameter" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-alpha-c-box")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Modell C")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/model-d-parameter" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-alpha-d-box")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Modell D")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/model-e-parameter" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-alpha-e-box")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Modell E")]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                { attrs: { to: "/model-f-parameter" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-alpha-f-box")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-title", [_vm._v("Modell F")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isGroupAdmin
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/reset-all-settings" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-reload")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Einstellungen zurücksetzen"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }