import axios from 'axios';
import { IChanceWeight } from '@/shared/model/chanceWeight.model';

const baseApiUrl = 'api/chance-weights';

export default class ChanceWeightService {
  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(chanceWeightId: number): Promise<IChanceWeight> {
    return new Promise<IChanceWeight>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/threshold/${chanceWeightId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(chanceWeightId: number): Promise<IChanceWeight> {
    return new Promise<IChanceWeight>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${chanceWeightId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public fullUpdate(chanceWeight: IChanceWeight): Promise<IChanceWeight> {
    return new Promise<IChanceWeight>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${chanceWeight.id}`, chanceWeight)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(chanceWeight: IChanceWeight): Promise<IChanceWeight> {
    return new Promise<IChanceWeight>((resolve, reject) => {
      axios
        .post(baseApiUrl, chanceWeight)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
