import axios from 'axios';

import { ICustomer } from '@/shared/model/customer.model';
import { IDocument } from '@/shared/model/document.model';
import { SendOffer } from '@/shared/model/enumerations/send-offer.model';
import { ICustomerMap } from '@/shared/model/cusotmerMap.model';
import { ICustomerList } from '@/shared/model/customer-list.model';
import { IFullReport } from '@/shared/model/full-report.model';
import { IMarketer } from '@/shared/model/marketer.model';
import { ICustomerListEdit } from '@/shared/model/customer-list-edit.model';

const baseApiUrl = 'api/customers';
const baseAdminApiUrl = 'api/admin/customers';
const baseGroupApi = 'api/groups/available-customer';

export default class CustomerService {
  public find(id: number): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err.response.status === 404) {
            resolve(null);
          } else {
            reject(err);
          }
        });
    });
  }

  public retrieve(fromData: string, toDate: string, statusId: string): Promise<ICustomerList[]> {
    let url = baseApiUrl;
    const params = new URLSearchParams();

    if (fromData) {
      params.append('fromDate', fromData);
    }
    if (toDate) {
      params.append('toDate', toDate);
    }
    if (statusId) {
      params.append('status', statusId);
    }
    const queryString = params.toString();
    if (queryString) {
      url += '?' + queryString;
    }
    return new Promise<ICustomerList[]>((resolve, reject) => {
      axios
        .get(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveKanbanView(fromData: string, toDate: string, searchTerm: string): Promise<ICustomerList[]> {
    let url = baseApiUrl + '/kanban';
    const params = new URLSearchParams();

    if (fromData) {
      params.append('fromDate', fromData);
    }
    if (toDate) {
      params.append('toDate', toDate);
    }
    if (searchTerm) {
      params.append('query', searchTerm);
    }

    const queryString = params.toString();
    if (queryString) {
      url += '?' + queryString;
    }
    return new Promise<ICustomerList[]>((resolve, reject) => {
      axios
        .get(url)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveAdminsCustomers(): Promise<ICustomerList[]> {
    return new Promise<ICustomerList[]>((resolve, reject) => {
      axios
        .get(baseAdminApiUrl)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveLead(): Promise<ICustomerList[]> {
    return new Promise<ICustomerList[]>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/leads`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public changeCustomerStatus(customerId: number, statusId: number): Promise<ICustomerList[]> {
    return new Promise<ICustomerList[]>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${customerId}/status/${statusId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public numOfLead(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/leads/count`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICustomer): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateCustomerList(entity: ICustomerListEdit): Promise<ICustomerListEdit> {
    return new Promise<ICustomerListEdit>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/list/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getCustomerListData(customerListId: number): Promise<ICustomerListEdit> {
    return new Promise<ICustomerListEdit>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/list/${customerListId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public suggest(text: string): Promise<ICustomer[]> {
    return new Promise<ICustomer[]>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/suggestion/${text}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public generateCapaFullPdf(id: number, reportData: IFullReport): Promise<IDocument> {
    return new Promise<IDocument>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/full-report/${id}`, reportData)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendDocumentToEmail(documentId: number, sendOffer: SendOffer): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/send-capafull-mail/${documentId}`, sendOffer, {
          headers: {
            'content-type': 'application/json',
          },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAllCustomerMapData(): Promise<ICustomerMap[]> {
    return new Promise<ICustomerMap[]>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/map`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public changeLeadStatus(customerId: number, active: boolean): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/${customerId}/isLead/${active}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public confirmCustomer(customerId: number): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/${customerId}/confirm-address`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAvailableCustomer(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      axios
        .get(baseGroupApi)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public addMarketersToCustomer(customerId: number, marketerId: number): Promise<IMarketer[]> {
    return new Promise<IMarketer[]>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/marketers/${customerId}/${marketerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public disableDirectCall(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/${customerId}/cancel-direct-call`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteAccess(customerId: number, marketerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/marketers/${customerId}/${marketerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveAllCustomersMarketer(customerId: number): Promise<IMarketer[]> {
    return new Promise<IMarketer[]>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/external-marketers/${customerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
