var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    {
      staticClass: "custom-app-bar",
      class: !_vm.isCalculatorRoute ? "border-bottom" : "",
      attrs: { app: "", flat: "" },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "mx-3 mt-1 px-2",
          class: _vm.isCalculatorRoute ? "floating-header" : "",
          staticStyle: { height: "80px" },
          attrs: { align: "center", justify: "space-between" },
        },
        [
          !_vm.isCalculatorRoute
            ? _c(
                "v-toolbar-title",
                { staticClass: "ml-5" },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0", attrs: { align: "center" } },
                    [
                      _c("img", {
                        staticClass: "main-logo",
                        attrs: {
                          src: require("../../../content/images/logo2.png"),
                          alt: "logo",
                          width: "711",
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _vm.customer && _vm.isCalculatorRoute
                ? _c(
                    "v-row",
                    {
                      staticClass: "ma-0 flex-nowrap",
                      attrs: { align: "center", justify: "start" },
                    },
                    [
                      _c("lazy-auth-image", {
                        attrs: {
                          size: "large",
                          "is-avatar": true,
                          id: _vm.customer.thumbnailId,
                        },
                      }),
                      _vm._v(" "),
                      _vm.customer.firstPerson
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "3", justify: "center" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "ma-0 text-h7 width-fit font-weight-bold",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("formatPersonalInfo")(
                                          _vm.customer.firstPerson
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.customer.secondPerson
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "ma-0 text-h7 width-fit font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm._f("formatPersonalInfo")(
                                              _vm.customer.secondPerson
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasAddress
                                ? _c(
                                    "p",
                                    { staticClass: "ma-0 font-14 width-fit" },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm._f("formatAddress")(
                                              _vm.customer.propertyData
                                                .objectAddress
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.customer && _vm.customer.status
                        ? _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { align: "center" },
                                },
                                [
                                  _vm.statuses && _vm.customer.status
                                    ? _c(
                                        "v-stepper",
                                        {
                                          staticClass:
                                            "d-flex flex-row flex-nowrap transparent",
                                          attrs: { elevation: "0" },
                                        },
                                        [
                                          _c(
                                            "v-stepper-header",
                                            { staticClass: "fit-height" },
                                            _vm._l(
                                              _vm.statuses,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    item.id !==
                                                    _vm.customer?.status?.id
                                                      ? _c(
                                                          "v-row",
                                                          {
                                                            staticClass: "ma-0",
                                                            attrs: {
                                                              align: "center",
                                                              justify: "start",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-stepper-step",
                                                              {
                                                                staticClass:
                                                                  "pa-1",
                                                                attrs: {
                                                                  color:
                                                                    item?.color,
                                                                  complete: "",
                                                                  "complete-icon":
                                                                    item?.arrange <
                                                                    _vm.customer
                                                                      ?.status
                                                                      ?.arrange
                                                                      ? "mdi-check"
                                                                      : "mdi-dots-horizontal",
                                                                },
                                                              },
                                                              [
                                                                item?.id ===
                                                                _vm.customer
                                                                  ?.status?.id
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item?.name
                                                                        )
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            index <
                                                            _vm.statuses
                                                              ?.length -
                                                              1
                                                              ? _c(
                                                                  "v-divider",
                                                                  {
                                                                    staticClass:
                                                                      "bg-black",
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "v-row",
                                                          {
                                                            staticClass: "ma-0",
                                                            attrs: {
                                                              align: "center",
                                                              justify: "start",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-chip",
                                                              {
                                                                staticClass:
                                                                  "me-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.customer
                                                                      ?.status
                                                                      ?.name
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            index <
                                                            _vm.statuses
                                                              ?.length -
                                                              1
                                                              ? _c(
                                                                  "v-divider",
                                                                  {
                                                                    staticClass:
                                                                      "bg-black",
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isNotCustomerRoute && !_vm.isAdmin
            ? _c(
                "v-col",
                { staticStyle: { "max-width": "16%" }, attrs: { md: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "rounded-lg",
                      attrs: {
                        color: "primary",
                        elevation: "0",
                        loading: _vm.savingCustomer,
                        block: "",
                      },
                      on: { click: _vm.saveAndGoToCustomerList },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-account-multiple")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "ma-0 px-2" }, [_vm._v("kunden")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.customer != null &&
                  _vm.customer.customerId != null &&
                  _vm.isCalculatorRoute
                    ? _c(
                        "v-row",
                        {
                          staticClass: "ma-0 mt-1 subtitle-2",
                          attrs: { align: "center", justify: "space-between" },
                        },
                        [
                          _c("span", [_vm._v("Kd-ID:")]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.customer.customerId))]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }