import { Component, Provide, Vue } from 'vue-property-decorator';

import UserService from '@/entities/user/user.service';
import AgeService from '@/entities/age/age.service';
import DigitalValueService from '@/entities/digital-value/digital-value.service';
import GuarantyService from '@/entities/guaranty/guaranty.service';
import ModelAParameterService from '@/entities/model-a-parameter/model-a-parameter.service';
import ModelBParameterService from '@/entities/model-b-parameter/model-b-parameter.service';
import ModelCParameterService from '@/entities/model-c-parameter/model-c-parameter.service';
import ModelDParameterService from '@/entities/model-d-parameter/model-d-parameter.service';
import ModelEParameterService from '@/entities/model-e-parameter/model-e-parameter.service';
import ModelFParameterService from '@/entities/model-f-parameter/model-f-parameter.service';
import ModelASystemParameterService from '@/entities/system-parameter/model-a-system-parameter/model-a-system-parameter.service';
import ModelCSystemParameterService from '@/entities/system-parameter/model-c-system-parameter/model-c-system-parameter.service';
import ModelESystemParameterService from '@/entities/system-parameter/model-e-system-parameter/model-e-system-parameter.service';
// @ts-ignore
import LiveDurationService from '@/entities/system-parameter/live-duration-parameter/live-duration.service';
import ValueDeclarerService from '@/entities/value-declarer/value-declarer.service';
import CustomerService from '@/customer/customer.service';
import ModelGSystemParameterService from '@/entities/system-parameter/model-g-system-parameter/model-g-system-parameter.service';
import ModelHSystemParameterService from '@/entities/system-parameter/model-h-system-parameter/model-h-system-parameter.service';
import ModelISystemParameterService from '@/entities/system-parameter/model-i-system-parameter/model-i-system-parameter.service';

@Component
export default class Entities extends Vue {
  @Provide('userService') private userService = () => new UserService();
  @Provide('ageService') private ageService = () => new AgeService();
  @Provide('digitalValueService') private digitalValueService = () => new DigitalValueService();
  @Provide('guarantyService') private guarantyService = () => new GuarantyService();
  @Provide('modelAParameterService') private modelAParameterService = () => new ModelAParameterService();
  @Provide('modelBParameterService') private modelBParameterService = () => new ModelBParameterService();
  @Provide('modelCParameterService') private modelCParameterService = () => new ModelCParameterService();
  @Provide('modelDParameterService') private modelDParameterService = () => new ModelDParameterService();
  @Provide('modelEParameterService') private modelEParameterService = () => new ModelEParameterService();
  @Provide('modelFParameterService') private modelFParameterService = () => new ModelFParameterService();
  @Provide('modelASystemParameterService') private modelASystemParameterService = () => new ModelASystemParameterService();
  @Provide('modelCSystemParameterService') private modelCSystemParameterService = () => new ModelCSystemParameterService();
  @Provide('modelESystemParameterService') private modelESystemParameterService = () => new ModelESystemParameterService();
  @Provide('modelGSystemParameterService') private modelGSystemParameterService = () => new ModelGSystemParameterService();
  @Provide('modelHSystemParameterService') private modelHSystemParameterService = () => new ModelHSystemParameterService();
  @Provide('modelISystemParameterService') private modelISystemParameterService = () => new ModelISystemParameterService();
  @Provide('liveDurationService') private liveDurationService = () => new LiveDurationService();
  @Provide('valueDeclarerService') private valueDeclarerService = () => new ValueDeclarerService();
  @Provide('customerService') private customerService = () => new CustomerService();
  // jhipster-needle-add-entity-service-to-entities-component - JHipster will import entities services here
}
